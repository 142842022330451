import axios from 'axios';
import { signOut } from 'firebase/auth';

import { auth } from '@/lib/firebase';

export const api = axios.create({
  baseURL: `${import.meta.env.VITE_DOMAIN}/api/v1`,
  withCredentials: true,
});

// redirect to login page if unauthorized error from api
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 ||
      error.response?.message === 'Network Error' ||
      error.response?.message === 'Unauthorized'
    ) {
      localStorage.removeItem('userData');
      signOut(auth);
      window.location.reload();
    }
  },
);
