import {
  type QueryClient,
  queryOptions,
  useMutation,
} from '@tanstack/react-query';

import { api } from '@/app/api';

import type {
  HotelSchema,
  HotelResponseSchema,
  HotelFormSchema,
  HotelSettingsSchema,
  HotelEventSpaceFormSchema,
  HotelAttractionsFormSchema,
} from '../schemas';

// Hotels
export const fetchHotels = async () => {
  return api
    .get<HotelResponseSchema>('/hotels')
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        throw new Error('Unauthorized');
      }
    });
};

export const fetchHotel = async (id: string) => {
  const hotel = await api
    .get<HotelSchema>(`/hotels/${id}`)
    .then((res) => res.data);

  if (!hotel) {
    throw new Error(`Hotel with id "${id}" not found!`);
  }

  return hotel;
};

export const updateHotel = async (
  hotelId: string,
  data: Partial<
    HotelFormSchema & {
      events?: HotelEventSpaceFormSchema;
    } & HotelAttractionsFormSchema
  >,
) => {
  await api.put(`/hotels/${hotelId}`, data);
};

// Hotel Settings
export const fetchHotelSettings = async () => {
  return api
    .get<HotelSettingsSchema>('/hotels/settings')
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        throw new Error('Unauthorized');
      }
    });
};

export const updateHotelSettings = async (data: HotelSettingsSchema) => {
  await api.patch('/hotels/settings', data);
};

// Queries and Mutations
export const hotelsQueryOptions = queryOptions({
  queryKey: ['hotels'],
  queryFn: () => fetchHotels(),
});

export const hotelSettingsQueryOptions = queryOptions({
  queryKey: ['hotelSettings'],
  queryFn: () => fetchHotelSettings(),
});

export const hotelQueryOptions = (id: string) =>
  queryOptions({
    queryKey: ['hotels', { id }],
    queryFn: () => fetchHotel(id),
  });

export const useUpdateHotelMutation = (
  id: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  return useMutation({
    mutationFn: (
      data: Partial<
        HotelFormSchema & {
          events?: HotelEventSpaceFormSchema;
        } & HotelAttractionsFormSchema
      >,
    ) => updateHotel(id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotels', { id }],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });
};

export const useUpdateHotelSettingsMutation = (
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  return useMutation({
    mutationFn: (data: HotelSettingsSchema) => updateHotelSettings(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelSettings'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });
};
