import {
  type QueryClient,
  queryOptions,
  useMutation,
} from '@tanstack/react-query';
import { format } from 'date-fns';

import { api } from '@/app/api';

import { ReservationResponseSchema, ReservationSchema } from './schemas';

const fetchReservations = async (queryParams: {
  id?: string;
  hotelId?: string;
}) => {
  return await api
    .get<ReservationResponseSchema>('/reservations/', { params: queryParams })
    .then((res) => res.data);
};

export const cancelReservation = async (
  hotelId: string,
  reservationId: string,
  data: ReservationSchema,
) => {
  await api
    .put(`/reservations/${hotelId}/${reservationId}`, {
      bookingStatus: 'cancelled',
    })
    .then((res) => res.data);

  await api.post('/emails/cancel', {
    domain: import.meta.env.VITE_DOMAIN,
    bookingRef: reservationId,
    guest: {
      name: data.guest.name,
      email: data.guest.email,
      guests: `${data.adults} adult${data.adults > 1 ? 's' : ''} ${
        data.children > 0
          ? `${data.children} child${data.children === 1 ? '' : 'ren'}`
          : ''
      }`,
    },
    reservation: {
      checkin: format(data.start, 'MMMM d, yyyy'),
      checkout: format(data.end, 'MMMM d, yyyy'),
      room: data.room.name,
    },
    hotel: {
      name: data.hotel.name,
      email: data.hotel.emails[0],
      phone: data.hotel.contactNumbers[0],
      address: data.hotel.address,
    },
  });
};

export const removeReservation = async (
  hotelId: string,
  reservationId: string,
) => {
  await api.delete(`/reservations/${hotelId}/${reservationId}`);
};

export const reservationsQueryOptions = (
  queryParams: {
    id?: string;
    hotelId?: string;
  } = {},
) =>
  queryOptions({
    queryKey: ['reservations'],
    queryFn: () => fetchReservations(queryParams),
  });

export const useCancelReservationMutation = (
  hotelId: string,
  reservationId: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  return useMutation({
    mutationFn: (data: ReservationSchema) =>
      cancelReservation(hotelId, reservationId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reservations'] });
      successCb();
    },
    onError: (error) => errorCb(error),
  });
};

export const useRemoveReservationMutation = (
  hotelId: string,
  reservationId: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  return useMutation({
    mutationFn: () => removeReservation(hotelId, reservationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reservations'] });
      successCb();
    },
    onError: (error) => errorCb(error),
  });
};
