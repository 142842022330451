import {
  type QueryClient,
  queryOptions,
  useMutation,
} from '@tanstack/react-query';

import { api } from '@/app/api';

import {
  CreateUserFormSchema,
  UpdateUserFormSchema,
  UpdateUserPasswordFormSchema,
  UserResponseSchema,
} from './schemas';

// APIs
const fetchUsers = async () => {
  return await api.get<UserResponseSchema>('/users').then((res) => res.data);
};

const createUser = async (data: CreateUserFormSchema) => {
  await api.post('/users', data);
};

const updateUser = async (id: string, data: UpdateUserFormSchema) => {
  await api.put(`/users/${id}`, data);
};

const updateUserPassword = async (
  id: string,
  data: UpdateUserPasswordFormSchema,
) => {
  await api.put(`/users/${id}`, { password: data.password });
};

const deleteUser = async (id: string) => {
  await api.delete(`/users/${id}`);
};

// Queries and Mutations
export const usersQueryOptions = () =>
  queryOptions({
    queryKey: ['users'],
    queryFn: () => fetchUsers(),
  });

export const useUserCreateMutation = (
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) =>
  useMutation({
    mutationFn: (data: CreateUserFormSchema) => createUser(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

export const useUserMutations = (
  id: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  const edit = useMutation({
    mutationFn: (data: UpdateUserFormSchema) => updateUser(id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const editPassword = useMutation({
    mutationFn: (data: UpdateUserPasswordFormSchema) =>
      updateUserPassword(id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const remove = useMutation({
    mutationFn: () => deleteUser(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  return { edit, editPassword, remove };
};
