import {
  type QueryClient,
  queryOptions,
  useMutation,
} from '@tanstack/react-query';

import { api } from '@/app/api';

import { FacilitiesResponseSchema, FacilityFormSchema } from '../schemas';

// APIs
export const fetchHotelFacilities = async (id: string) => {
  const hotel = await api
    .get<FacilitiesResponseSchema>(`/hotels/${id}/facility`)
    .then((res) => res.data);

  if (!hotel) {
    throw new Error(`Facilities for hotel with id "${id}" not found!`);
  }

  return hotel;
};

export const createHotelFacility = async (
  id: string,
  data: FacilityFormSchema,
) => {
  await api.post(`/hotels/${id}/facility`, data);
};

export const updateHotelFacility = async (
  hotelId: string,
  facilityId: string,
  data: FacilityFormSchema,
) => {
  await api.put(`/hotels/${hotelId}/facility/${facilityId}`, data);
};

export const deleteHotelFacility = async (
  hotelId: string,
  facilityId: string,
) => {
  await api.delete(`/hotels/${hotelId}/facility/${facilityId}`);
};

// Queries and Mutations
export const hotelFacilitiesQueryOptions = (id: string) =>
  queryOptions({
    queryKey: ['hotelFacility'],
    queryFn: () => fetchHotelFacilities(id),
  });

export const useFacilityMutations = (
  hotelId: string,
  facilityId: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  const add = useMutation({
    mutationFn: (data: FacilityFormSchema) =>
      createHotelFacility(hotelId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelFacility'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const edit = useMutation({
    mutationFn: (data: FacilityFormSchema) =>
      updateHotelFacility(hotelId, facilityId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelFacility'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const remove = useMutation({
    mutationFn: () => deleteHotelFacility(hotelId, facilityId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelFacility'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  return { add, edit, remove };
};
