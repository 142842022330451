import {
  type QueryClient,
  queryOptions,
  useMutation,
} from '@tanstack/react-query';

import { api } from '@/app/api';

import { PageFormSchema, PageSchema } from './schemas';

export const fetchPage = async (id: string) => {
  const page = await api
    .get<PageSchema>(`/pages/${id}`)
    .then((res) => res.data);

  if (!page) {
    throw new Error(`Page with id "${id}" not found!`);
  }

  return page;
};

export const updatePage = async (id: string, data: PageFormSchema) => {
  return api.put<PageSchema>(`/pages/${id}`, data).then((res) => res.data);
};

export const pageQueryOptions = (id: string) =>
  queryOptions({
    queryKey: ['pages', { id }],
    queryFn: () => fetchPage(id),
  });

export const useUpdatePageMutation = (
  id: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  return useMutation({
    mutationFn: (data: PageFormSchema) => updatePage(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pages', { id }] });
      successCb();
    },
    onError: (error) => errorCb(error),
  });
};
