import toast from 'react-hot-toast';

import { useMutation } from '@tanstack/react-query';

import { api } from '@/app/api';

export const uploadImages = async (data: FormData) => {
  await api.post(`/upload`, data);
};

export const useImageUploadMutation = (errorCb: (error: Error) => void) => {
  return useMutation({
    mutationFn: (data: FormData) => uploadImages(data),
    onError: (error) => {
      toast('Something went wrong. Try again later.', {
        className: 'bg-red-500 text-white',
        duration: 2500,
      });
      console.error('An error has occured: ', error);
      errorCb(error);
    },
  });
};
