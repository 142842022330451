import {
  type QueryClient,
  queryOptions,
  useMutation,
} from '@tanstack/react-query';

import { api } from '@/app/api';

import {
  AllocationFormSchema,
  AllocationResponseSchema,
  RoomFormSchema,
  RoomResponseSchema,
  SearchabilityFormSchema,
} from '../schemas';

// APIs
// rooms
export const fetchHotelRooms = async (id: string) => {
  const rooms = await api
    .get<RoomResponseSchema>(`/hotels/${id}/rooms`)
    .then((res) => res.data);

  if (!rooms) {
    throw new Error(`Rooms for hotel with id "${id}" not found!`);
  }

  return rooms;
};

export const createHotelRoom = async (id: string, data: RoomFormSchema) => {
  await api.post(`/hotels/${id}/rooms`, {
    ...data,
    children: data.child, // children is reserved keyword in javascript
    amenities: data.amenities?.map((el) => el.value),
  });
};

export const updateHotelRoom = async (
  hotelId: string,
  roomId: string,
  data: RoomFormSchema,
) => {
  await api.put(`/hotels/${hotelId}/rooms/${roomId}`, {
    ...data,
    children: data.child, // children is reserved keyword in javascript
    amenities: data.amenities?.map((el) => el.value),
  });
};

export const deleteHotelRoom = async (hotelId: string, roomId: string) => {
  await api.delete(`/hotels/${hotelId}/rooms/${roomId}`);
};

// allocation
export const fetchRoomAllocation = async (
  hotelId: string,
  roomId: string,
  startDate: string,
  endDate: string,
) => {
  const allocation = await api
    .get<AllocationResponseSchema>(`/allocations`, {
      params: {
        hotelId,
        roomId,
        startDate,
        endDate,
      },
    })
    .then((res) => res.data);

  return allocation;
};

export const updateRoomAllocation = async (data: AllocationFormSchema) => {
  const allocation = await api
    .put(`/allocations/${data.date}-${data.hotelId}-${data.roomId}`, data)
    .then((res) => res.data);

  return allocation;
};

// searchability / pax / lookup table
export const updateRoomSearchability = async (
  hotelId: string,
  data: SearchabilityFormSchema,
) => {
  const searchability = await api
    .patch(`/hotels/${hotelId}/searchability`, data)
    .then((res) => res.data);

  return searchability;
};

// Queries and Mutations
export const hotelRoomsQueryOptions = (id: string) =>
  queryOptions({
    queryKey: ['hotelRoom'],
    queryFn: () => fetchHotelRooms(id),
  });

export const hotelRoomAllocationQueryOptions = (
  hotelId: string,
  roomId: string,
  startDate: string,
  endDate: string,
) =>
  queryOptions({
    queryKey: ['hotelRoomAllocation', startDate, endDate],
    queryFn: () => fetchRoomAllocation(hotelId, roomId, startDate, endDate),
  });

export const useHotelRoomAllocationMutation = (
  queryClient: QueryClient,
  start: string,
  end: string,
  errorCb: (error: Error) => void,
) => {
  return useMutation({
    mutationFn: (data: AllocationFormSchema) => updateRoomAllocation(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelRoomAllocation', start, end],
      });
    },
    onError: (error) => errorCb(error),
  });
};

export const useRoomMutations = (
  hotelId: string,
  roomId: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  const add = useMutation({
    mutationFn: (data: RoomFormSchema) => createHotelRoom(hotelId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelRoom'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const edit = useMutation({
    mutationFn: (data: RoomFormSchema) =>
      updateHotelRoom(hotelId, roomId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelRoom'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const remove = useMutation({
    mutationFn: () => deleteHotelRoom(hotelId, roomId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelRoom'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  return { add, edit, remove };
};

export const useHotelRoomSearchabilityMutation = (
  hotelId: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  return useMutation({
    mutationFn: (data: SearchabilityFormSchema) =>
      updateRoomSearchability(hotelId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['hotelRoom'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });
};
