import { useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldError,
  UseFormGetFieldState,
  UseFormGetValues,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import toast from 'react-hot-toast';

import { zodResolver } from '@hookform/resolvers/zod';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { addDays, eachDayOfInterval, format, isToday } from 'date-fns';
import {
  CalendarIcon,
  Check,
  ChevronLeft,
  ChevronRight,
  X,
} from 'lucide-react';
import { z } from 'zod';

import { useImageUploadMutation } from '@/common/api/upload';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { generateFilename, randomStringGenerator } from '@/lib/helpers';
import { cn } from '@/lib/utils';

import {
  hotelRoomAllocationQueryOptions,
  useHotelRoomAllocationMutation,
  useRoomMutations,
} from '../api';
import { RoomFormSchema } from '../schemas';

type RoomFormProps = {
  type: 'create' | 'update';
  closeSheet: () => void;
  unsetRoomId: () => void;
  roomId?: string;
  room?: RoomFormSchema;
};

const defaultRoom: RoomFormSchema = {
  name: '',
  adults: '',
  child: '0',
  beds: '',
  bathrooms: '',
  amenities: [{ value: '' }],
  images: [],
  configuration: {
    inventory: '',
    cost: '',
    available: true,
  },
};

const route = getRouteApi('/auth/hotels/$hotelId');

export default function RoomForm({
  type,
  closeSheet,
  unsetRoomId,
  roomId,
  room = defaultRoom,
}: RoomFormProps) {
  const formSchema = useRef(RoomFormSchema);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const { hotelId } = route.useParams();
  const form = useForm<RoomFormSchema>({
    resolver: zodResolver(formSchema.current),
    defaultValues: room,
  });
  const queryClient = useQueryClient();

  const { add, edit, remove } = useRoomMutations(
    hotelId,
    roomId!,
    queryClient,
    () => {
      toast('Success', {
        className: 'bg-green-500 text-white',
        duration: 2000,
      });
      unsetRoomId();
      closeSheet();
    },
    (error) => {
      toast('Something went wrong.', {
        className: 'bg-red-500 text-white',
        duration: 2500,
      });
      console.error('An error has occured: ', error);
      setIsSubmitting(false);
    },
  );
  const uploadMutation = useImageUploadMutation(() => {
    setIsSubmitting(false);
  });

  const onSubmit = async (formRoom: z.infer<typeof formSchema.current>) => {
    setIsSubmitting(true);

    const { images, ...fRoom } = formRoom;
    const fImages: RoomFormSchema['images'] = [];
    if (images) {
      const imagesFormData = new FormData();
      for (const fImg of images) {
        if (fImg.file) {
          const name = `${hotelId}-rooms-${randomStringGenerator()}-${fImg.file.name.toLowerCase()}`;

          fImages.push({ name, url: generateFilename(name) });
          imagesFormData.append('images', fImg.file, name);
        } else {
          fImages.push(fImg);
        }
      }
      await uploadMutation.mutateAsync(imagesFormData);
      // TODO: delete existing images
    }

    if (type === 'update') {
      const parsedFormRoom = formSchema.current
        .pick(form.formState.dirtyFields as Record<string, unknown>)
        .parse(fRoom);
      await edit.mutateAsync({
        images: fImages,
        ...parsedFormRoom,
      });
    } else if (type === 'create') {
      const parsedFormRoom = formSchema.current.parse(fRoom);
      await add.mutateAsync({
        images: fImages,
        ...parsedFormRoom,
      });
    }
  };

  const onDelete = () => {
    if (!isConfirmDelete) {
      setIsConfirmDelete(true);
      return;
    }
    setIsSubmitting(true);
    remove.mutate();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        {type === 'create' && (
          <>
            <SheetTitle className="hidden">Room</SheetTitle>
            <SheetDescription className="hidden">
              Create room form
            </SheetDescription>

            <h1 className="text-lg font-semibold">
              Room Rate and Availability
            </h1>
            <RoomRateInventorySection control={form.control} />

            <h1 className="text-lg font-semibold">Room Details</h1>
            <RoomDetailsSection
              control={form.control}
              getFieldState={form.getFieldState}
            />
          </>
        )}

        {type === 'update' && (
          <>
            <SheetHeader>
              <SheetTitle>Room: {form.getValues('name')}</SheetTitle>
              <SheetDescription>
                <VisuallyHidden.Root>Update room form</VisuallyHidden.Root>
              </SheetDescription>
            </SheetHeader>

            <Tabs defaultValue="room-details">
              <TabsList className="w-full grid-cols-2">
                <TabsTrigger value="room-details" className="font-semibold">
                  Details
                </TabsTrigger>
                <TabsTrigger value="room-allocation" className="font-semibold">
                  Rate and Availability
                </TabsTrigger>
              </TabsList>

              <TabsContent value="room-details">
                <RoomDetailsSection
                  control={form.control}
                  getFieldState={form.getFieldState}
                />
              </TabsContent>

              <TabsContent value="room-allocation">
                <RoomRateInventorySection control={form.control} />
                <RoomAvailabilitySection
                  control={form.control}
                  getValues={form.getValues}
                  roomId={roomId!}
                  hotelId={hotelId}
                />
              </TabsContent>
            </Tabs>
          </>
        )}

        <div className="flex gap-2 items-center justify-end">
          {roomId && (
            <>
              {isConfirmDelete && (
                <span className="ml-2 text-sm">
                  Are you sure you want to delete?
                </span>
              )}
              <Button
                type="button"
                size="sm"
                disabled={isSubmitting}
                variant="destructive"
                onClick={onDelete}
              >
                {'Delete'}
              </Button>
              {isConfirmDelete && (
                <Button
                  type="button"
                  size="sm"
                  disabled={isSubmitting}
                  variant="outline"
                  onClick={() => setIsConfirmDelete(false)}
                >
                  {'Cancel'}
                </Button>
              )}
            </>
          )}
          <Button disabled={isSubmitting} type="submit" size="sm">
            {'Save'}
          </Button>
        </div>
      </form>
    </Form>
  );
}

interface RoomFormSchemaProps {
  control: Control<RoomFormSchema>;
}

function RoomRateInventorySection({ control }: RoomFormSchemaProps) {
  return (
    <div className="flex gap-4">
      <FormField
        control={control}
        name="configuration.cost"
        render={({ field, fieldState: { error } }) => (
          <FormItem className="flex-1">
            <FormLabel>
              Cost<span className="text-red-600">*</span>
              <span className="font-normal text-slate-400">
                {' '}
                (PHP per night)
              </span>
            </FormLabel>
            <FormControl>
              <Input
                type="number"
                className={`${
                  error
                    ? 'border-2 border-red-500 focus-visible:outline-none'
                    : 'focus-visible:ring-2 '
                }`}
                placeholder="5000"
                {...field}
              />
            </FormControl>
            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="configuration.inventory"
        render={({ field, fieldState: { error } }) => (
          <FormItem className="flex-1">
            <FormLabel>
              Inventory<span className="text-red-600">*</span>
            </FormLabel>
            <FormControl>
              <Input
                type="number"
                className={`${
                  error
                    ? 'border-2 border-red-500 focus-visible:outline-none'
                    : 'focus-visible:ring-2 '
                }`}
                placeholder="3"
                {...field}
              />
            </FormControl>
            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />
    </div>
  );
}

function RoomAvailabilitySection({
  control,
  getValues,
  roomId,
  hotelId,
}: RoomFormSchemaProps & {
  getValues: UseFormGetValues<RoomFormSchema>;
  roomId: string;
  hotelId: string;
}) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [dateRange, setDateRange] = useState<Date[]>(() =>
    eachDayOfInterval({
      start: date,
      end: addDays(new Date(), 9),
    }),
  );
  const queryClient = useQueryClient();
  const { isPending, isRefetching, data } = useQuery(
    hotelRoomAllocationQueryOptions(
      hotelId,
      roomId,
      format(date, 'yyyy-MMM-dd'),
      format(addDays(date, 9).toDateString(), 'yyyy-MMM-dd'),
    ),
  );
  const allocationUpdate = useHotelRoomAllocationMutation(
    queryClient,
    format(date, 'yyyy-MMM-dd'),
    format(addDays(date, 9).toDateString(), 'yyyy-MMM-dd'),
    (error) => {
      toast('Something went wrong.', {
        className: 'bg-red-500 text-white',
        duration: 2500,
      });
      console.error('An error has occured: ', error);
    },
  );

  const allocation = data?.allocation;

  const handleUpdateDate = (date: Date) => {
    const endDate = addDays(date, 9);
    setDate(date);
    setDateRange(
      eachDayOfInterval({
        start: date,
        end: endDate,
      }),
    );

    queryClient.invalidateQueries({
      queryKey: [
        'hotelRoomAllocation',
        format(date, 'yyyy-MMM-dd'),
        format(endDate, 'yyyy-MMM-dd'),
      ],
    });
  };

  const handleUpdateAvailability = (date: Date, availability: boolean) => {
    allocationUpdate.mutate({
      hotelId,
      roomId,
      date: format(date, 'yyyy-MM-dd'),
      availability,
    });
  };

  const handleUpdateInventory = (date: Date, inventory: string) => {
    allocationUpdate.mutate({
      hotelId,
      roomId,
      date: format(date, 'yyyy-MM-dd'),
      inventory,
      reservations: '0',
    });
  };

  if (isPending || isRefetching) return <div>Loading...</div>;

  return (
    <>
      <div className="flex items-center justify-between mt-2">
        <FormField
          control={control}
          name="configuration.available"
          render={({ field }) => (
            <FormItem className="flex items-center gap-2 my-2">
              <FormLabel>Available</FormLabel>
              <FormControl>
                <Switch
                  className="mt-0"
                  name={field.name}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <div className="flex gap-2">
          <Popover open={datePickerOpen} onOpenChange={setDatePickerOpen}>
            <PopoverTrigger asChild>
              <Button
                variant={'outline'}
                className={cn(
                  'w-[280px] justify-start text-left font-normal',
                  !date && 'text-muted-foreground',
                )}
                disabled={!getValues('configuration.available')}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {format(date, 'PPP')}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                disabled={{ before: new Date() }}
                onSelect={(_, date) => {
                  handleUpdateDate(date);
                  setDatePickerOpen(false);
                }}
              />
            </PopoverContent>
          </Popover>

          <Button
            variant="outline"
            size="icon"
            type="button"
            disabled={isToday(date) || date <= addDays(new Date(), -1)}
            onClick={() => handleUpdateDate(addDays(date, -9))}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>

          <Button
            variant="outline"
            size="icon"
            type="button"
            onClick={() => handleUpdateDate(addDays(date, 9))}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {getValues('configuration.available') && (
        <table className="w-full my-2">
          <colgroup>
            <col className="w-[15%]" />
            <col span={10} className="w-[8.5%]" />
          </colgroup>

          <thead>
            <tr>
              <th className="border px-4 py-2 text-right font-normal">Date</th>
              {dateRange.map((date) => (
                <th
                  key={format(date, 'yyyy-MM-dd')}
                  className="border py-2 text-center font-normal"
                >
                  <div
                    className={`text-sm ${
                      date <= addDays(new Date(), -1) ? 'opacity-50' : ''
                    }`}
                  >
                    {format(date, 'MMM')}
                  </div>
                  <div
                    className={`text-sm font-bold ${
                      date <= addDays(new Date(), -1) ? 'opacity-50' : ''
                    }`}
                  >
                    {format(date, 'd')}
                  </div>
                  <div
                    className={`text-xs text-slate-400 ${
                      date <= addDays(new Date(), -1) ? 'opacity-50' : ''
                    }`}
                  >
                    {format(date, 'EEEE')}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="border px-4 py-2 text-right">Availability</td>

              {dateRange.map((date) => {
                const formattedDate = format(date, 'yyyy-MM-dd');
                // default availability
                let isAvailable = getValues('configuration.available');
                // check if availability is set in the allocation
                const key = `${formattedDate}-${hotelId}-${roomId}`;
                if (allocation && key in allocation) {
                  const allocationKey = allocation[key];
                  if (allocationKey.availability !== undefined) {
                    isAvailable = allocationKey.availability;
                  }
                }

                return (
                  <td
                    key={`${formattedDate}-availability`}
                    className={`border px-4 py-2 text-white ${
                      date <= addDays(new Date(), -1)
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    } ${
                      isAvailable
                        ? 'cursor-pointer bg-green-600 hover:bg-green-700'
                        : 'cursor-pointer bg-red-600 hover:bg-red-700'
                    }`}
                    onClick={() => {
                      if (date <= addDays(new Date(), -1)) return;
                      handleUpdateAvailability(date, !isAvailable);
                    }}
                  >
                    <div className="flex justify-center">
                      {isAvailable ? (
                        <Check className="h-5 w-5" />
                      ) : (
                        <X className="h-5 w-5" />
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>

            <tr>
              <td className="border px-4 py-2 text-right">Inventory</td>

              {dateRange.map((date) => {
                const formattedDate = format(date, 'yyyy-MM-dd');
                // default inventory
                let inventory = getValues('configuration.inventory');
                const key = `${formattedDate}-${hotelId}-${roomId}`;
                // check if inventory/reservations is set in the allocation
                if (allocation && key in allocation) {
                  const allocationKey = allocation[key];
                  if (
                    allocationKey.inventory !== undefined ||
                    allocationKey.reservations !== undefined
                  ) {
                    const allocationKeyInventory =
                      allocationKey.inventory !== undefined
                        ? Number(allocationKey.inventory)
                        : Number(inventory);
                    const allocationKeyReservations =
                      allocationKey.reservations !== undefined
                        ? Number(allocationKey.reservations)
                        : 0;
                    inventory = (
                      allocationKeyInventory - allocationKeyReservations
                    ).toString();
                  }
                }

                return (
                  <td
                    key={`${formattedDate}-inventory`}
                    className="border px-4 py-2 text-center"
                  >
                    <PopoverInputField
                      defaultValue={inventory}
                      onSubmit={(value) => {
                        if (date <= addDays(new Date(), -1)) return;
                        handleUpdateInventory(date, value);
                      }}
                      disabled={date <= addDays(new Date(), -1)}
                    />
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
}

interface PopoverInputFieldProps {
  defaultValue?: string;
  onSubmit: (value: string) => void;
  disabled: boolean;
}

function PopoverInputField({
  defaultValue = '',
  onSubmit,
  disabled,
}: PopoverInputFieldProps) {
  const [isOpen, setIsOpen] = useState(false);
  const formSchema = z.object({ input: z.string().min(1, 'Required') });

  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { input: defaultValue },
  });

  const handleInputSubmit = (data: z.infer<typeof formSchema>) => {
    onSubmit(data.input);
    setIsOpen(false);
  };

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        reset();
      }}
    >
      <PopoverTrigger
        disabled={disabled}
        className="disabled:cursor-not-allowed disabled:opacity-50"
      >
        {defaultValue}
      </PopoverTrigger>
      <PopoverContent className="w-32">
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(handleInputSubmit)(e);
          }}
          className="flex gap-2 justify-center"
        >
          <Controller
            control={control}
            name="input"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                placeholder={defaultValue}
                className={`w-11 ${error ? 'border-red-500' : 'border-input'}`}
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Button type="submit" size="icon" variant="default">
            <Check />
          </Button>
        </form>
      </PopoverContent>
    </Popover>
  );
}

function RoomDetailsSection({
  control,
  getFieldState,
}: RoomFormSchemaProps & {
  getFieldState: UseFormGetFieldState<RoomFormSchema>;
}) {
  return (
    <>
      <FormField
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <FormItem>
            <FormLabel>
              Room Name<span className="text-red-600">*</span>
            </FormLabel>
            <FormControl>
              <Input
                className={`${
                  error
                    ? 'border-2 border-red-500 focus-visible:outline-none'
                    : 'focus-visible:ring-2 '
                }`}
                placeholder="Standard Room"
                autoComplete="off"
                {...field}
              />
            </FormControl>
            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />

      <div className="flex gap-4">
        <FormField
          control={control}
          name="adults"
          render={({ field, fieldState: { error } }) => (
            <FormItem className="flex-1">
              <FormLabel>
                Adults<span className="text-red-600">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="number"
                  className={`${
                    error
                      ? 'border-2 border-red-500 focus-visible:outline-none'
                      : 'focus-visible:ring-2 '
                  }`}
                  placeholder="1"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="child"
          render={({ field, fieldState: { error } }) => (
            <FormItem className="flex-1">
              <FormLabel>
                Children<span className="text-red-600">*</span>{' '}
                <span className="font-normal text-slate-400">
                  (0-12 years old)
                </span>
              </FormLabel>
              <FormControl>
                <Input
                  type="number"
                  className={`${
                    error
                      ? 'border-2 border-red-500 focus-visible:outline-none'
                      : 'focus-visible:ring-2 '
                  }`}
                  placeholder="0"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
      </div>

      <div className="flex gap-4">
        <FormField
          control={control}
          name="beds"
          render={({ field, fieldState: { error } }) => (
            <FormItem className="flex-1">
              <FormLabel>
                Beds<span className="text-red-600">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="number"
                  className={`${
                    error
                      ? 'border-2 border-red-500 focus-visible:outline-none'
                      : 'focus-visible:ring-2 '
                  }`}
                  placeholder="1"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="bathrooms"
          render={({ field, fieldState: { error } }) => (
            <FormItem className="flex-1">
              <FormLabel>
                Bathrooms<span className="text-red-600">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="number"
                  className={`${
                    error
                      ? 'border-2 border-red-500 focus-visible:outline-none'
                      : 'focus-visible:ring-2 '
                  }`}
                  placeholder="1"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
      </div>

      <RoomAmenities
        control={control}
        error={getFieldState('amenities').error}
      />

      <RoomImages control={control} />
    </>
  );
}

function RoomAmenities({
  control,
  error,
}: RoomFormSchemaProps & {
  error?: FieldError;
}) {
  const [amenity, setAmenity] = useState('');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'amenities',
  });

  return (
    <div className="h-96 flex flex-col space-y-2 mb-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <p className="text-sm py-3 font-medium leading-none">
            Amenities<span className="text-red-600">*</span>
          </p>
          {error?.root && (
            <span className="text-xs text-destructive font-medium">
              At least 1 amenity required
            </span>
          )}
        </div>
        <Button
          type="button"
          size="sm"
          onClick={() => {
            append({ value: amenity });
            setAmenity('');
          }}
        >
          Add Amenity
        </Button>
      </div>

      <div
        className={`w-full h-full p-2 border ${
          error?.root ? 'border-2 border-red-500' : 'border border-slate-400'
        } rounded overflow-y-scroll scroll`}
      >
        {fields.map((item, i) => (
          <Controller
            key={item.id}
            control={control}
            name={`amenities.${i}.value`}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-2">
                <div className="w-full flex gap-2 items-center">
                  <Input
                    className={`flex-1 ${
                      error
                        ? 'border-2 border-red-500 focus-visible:outline-none'
                        : 'focus-visible:ring-2 '
                    }`}
                    name={field.name}
                    value={field.value}
                    onChange={({ target }) => field.onChange(target.value)}
                  />
                  <Button
                    size="icon"
                    variant="destructive"
                    onClick={() => remove(i)}
                  >
                    <X />
                  </Button>
                </div>

                <div className="text-xs text-destructive font-medium">
                  {error?.message}
                </div>
              </div>
            )}
          />
        ))}
      </div>
    </div>
  );
}

function RoomImages({ control }: RoomFormSchemaProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'images',
  });

  const onFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const files = target.files;
    if (!files) return;
    const images = Array.from(files).map((file) => ({
      url: URL.createObjectURL(file),
      file,
    }));
    append(images);

    fileInputRef.current!.value = '';
  };

  return (
    <div className="h-96 flex flex-col space-y-2">
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium leading-none py-2">Images</div>
        <input
          ref={fileInputRef}
          type="file"
          name="roomImages"
          onChange={onFileChange}
          className="hidden"
          accept="image/png, image/jpeg"
          multiple
        />
        <Button
          type="button"
          size="sm"
          onClick={() => fileInputRef.current?.click()}
        >
          Add Image
        </Button>
      </div>

      <div className="w-full h-full p-2 grid grid-cols-5 gap-2 border border-slate-400 rounded overflow-y-scroll scroll">
        {fields.length === 0 ? (
          <p className="text-sm leading-none">No files yet</p>
        ) : (
          fields.map((item, i) => (
            <Controller
              key={item.id}
              control={control}
              name={`images.${i}`}
              render={() => (
                <div className="relative h-32 w-full border border-slate-400">
                  <img
                    src={item.url}
                    className="h-full w-full object-contain"
                  />
                  <button
                    className="absolute top-1 right-1 p-1 bg-red-400 rounded-full"
                    onClick={() => {
                      URL.revokeObjectURL(item.url!);
                      remove(i);
                    }}
                  >
                    <X className="w-3 h-3" />
                  </button>
                </div>
              )}
            />
          ))
        )}
      </div>
    </div>
  );
}
