import {
  type QueryClient,
  queryOptions,
  useMutation,
} from '@tanstack/react-query';

import { api } from '@/app/api';

import { NewsFormSchema, NewsResponseSchema } from './schemas';

// APIs
export const fetchAllNews = async () => {
  return await api.get<NewsResponseSchema>('/news').then((res) => res.data);
};

export const createNews = async (data: NewsFormSchema) => {
  await api.post('/news', data);
};

export const updateNews = async (id: string, data: NewsFormSchema) => {
  await api.put(`/news/${id}`, data);
};

export const deleteNews = async (id: string) => {
  await api.delete(`/news/${id}`);
};

// Queries and Mutations
export const newsQueryOptions = () =>
  queryOptions({
    queryKey: ['news'],
    queryFn: () => fetchAllNews(),
  });

export const useNewsMutations = (
  id: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  const add = useMutation({
    mutationFn: (data: NewsFormSchema) => createNews(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['news'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const edit = useMutation({
    mutationFn: (data: NewsFormSchema) => updateNews(id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['news'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const remove = useMutation({
    mutationFn: () => deleteNews(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['news'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  return { add, edit, remove };
};
